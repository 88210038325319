import React from 'react'
import { Container, Row, Col, Hidden } from 'react-grid-system'
import ReactMarkdown from 'react-markdown'
import { 
    Accordion, 
    AccordionItem, 
    AccordionItemHeading, 
    AccordionItemButton, 
    AccordionItemPanel 
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

export default function DataAccordion(props) {

    return (
        <Container className="accordion mt-20">
            <Row>
                <Hidden xs sm>
                    <Col md={2}></Col>
                </Hidden>
                <Col md={8}>
                    <hr />
                    {props.data ?
                    <Accordion>
                    {props.data.map((a) => (
                        <AccordionItem key={a.id}>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <h5>{a.title}</h5>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ReactMarkdown children={a.description} />
                            </AccordionItemPanel>
                        </AccordionItem>
                    ))}
                    </Accordion>     
                    : <h5 className="bg-red-700 text-white py-3 text-center">Error: There is no data for this accordion.</h5> }
                </Col>
                <Hidden xs sm>
                    <Col md={2}></Col>
                </Hidden>
            </Row>
        </Container>
    )
}